<template>
  <div>
    <div v-if="goalReached">
      <Border>
        <div class="item item3">
          You have reached your goal!
          <div class="lineItem">
            You have {{ trackReward.count }} out of {{ trackReward.goal }} from
            the '{{ trackReward.rewardName }}' reward
          </div>
          <div class="lineItem">
            A voucher has been added to the Home screen.
          </div>
          <div class="lineItem">
            Use the voucher to get your reward.
          </div>
        </div>
        <div class="right">
          <button @click="generateNewQr()">Get scanned again</button>
        </div>
      </Border>
    </div>
    <div v-if="allocationDone">
      <Border>
        <div class="item item3">
          1 reward point allocated!
          <div class="lineItem">
            You have {{ trackReward.count }} out of {{ trackReward.goal }} from
            the '{{ trackReward.rewardName }}' reward
          </div>
        </div>
        <div class="right">
          <button @click="generateNewQr()">Get scanned again</button>
        </div>
      </Border>
    </div>
    <div v-if="redeemed">
      <Border>
        <div class="item item3">
          <div class="lineItem item3">
            Voucher processed successfully</div>
        </div>
        <div class="lineItem item3">
          Enjoy your {{ voucher.actualReward }}!
        </div>
      </Border>
    </div>
    <div v-if="showQR">
      <Border>
        <!-- <Error v-bind:errors="errors" /> -->
        <div class="centerQR">
          <qrcode-vue :value="value" :size="size" level="H" class="whiteblock"></qrcode-vue>
        </div>
      </Border>
      <Border v-if="!showVouchers">
        <div class="item item3">
          Show this QR code to the vendor to get scanned and accumulate reward points
        </div>
      </Border>
      <Border v-if="showVouchers">
        <div class="item">
          <div class="lineItemHeader item3"> ~ {{ voucher.rewardName }} reward ~</div>
          <div class="lineItem">Show this QR code to '{{voucher.vendorName}}' to get scanned and redeem your reward</div>
        </div>
      </Border>
    </div>
  </div>
</template>

<script>
import Border from "@/components/Border.vue";
// import Error from "@/components/Error.vue";
import QrcodeVue from "qrcode.vue";
import Comms from "../shared";
export default {
  name: "QR",
  components: {
    Border,
    // Error,
    QrcodeVue,
  },
  data: function () {
    return {
      value: "",
      size: 330,
      showVouchers: false,
      allocationDone: false,
      goalReached: false,
      redeemed: false,
      showQR: true,
      pollCount: 0,
      pollMax: 15,
      pollInterval: 5000,
      errors: [],
      voucher: {
        voucherId: "",
        trackId: "",
        patronId: "",
        rewardId: "",
        vendorId: "",
        createDate: "",
        redeemDate: "",
        status: "",
        vendorName: "",
        rewardName: "",
        actualReward: "",
      },
      track: {
        trackId: "",
        patronId: "",
        rewardId: "",
        vendorId: "",
        lastQrId: "",
      },
      trackReward: {
        trackId: "",
        vendorId: "",
        vendorName: "",
        rewardId: "",
        rewardName: "",
        actualReward: "",
        patronId: "",
        count: -1,
        goal: -1,
        status: "",
      },
    };
  },
  methods: {
    initQrScreen() {
      if (!this.$store.state.authenticated) {
        this.$router.push({ name: "Home" });
      } else {
        this.redeemed = false;
        this.allocationDone = false;
        this.goalReached = false;
        this.showQR = true;
        this.pollCount = 0;
        // check if its a qr or voucher
        this.voucher = this.$store.state.voucher;
        if (this.voucher && this.voucher.voucherId) {
          // generate voucher
          this.showVouchers = true;
          this.value =
            "v|" +
            // new Date().getTime() +
            // "|" +
            this.$store.state.voucher.voucherId +
            "|" +
            this.$store.state.voucher.vendorId;
          // clear the voucher data in vuex
          this.$store.commit("setVoucher", null);
          // poll server and check if code was scanned. This should be a push notification in future
          // if you move away from screen , do not carry on polling
          this.pollVoucher(this.voucher);
        } else {
          // normal QR
          this.showVouchers = false;
          this.track.patronId = this.$store.state.sytUser.userID;
          this.track.lastQrId = this.generateUUID();
          // console.log("generateUUID: " + this.track.lastQrId);
          this.value =
            "n|" +
            this.track.lastQrId +
            "|" +
            this.track.patronId;
            // "|" +
            // this.$store.state.sytUser.userName;
          // console.log("value: " + this.value);
          this.pollQR(this.track);
        }
      }
    },
    generateNewQr() {
      this.initQrScreen();
    },
    generateUUID() {
      // Public Domain/MIT
      var d = new Date().getTime(); //Timestamp
      var d2 =
        (typeof performance !== "undefined" &&
          performance.now &&
          performance.now() * 1000) ||
        0; //Time in microseconds since page-load or 0 if unsupported
      return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
        /[xy]/g,
        function (c) {
          var r = Math.random() * 16; //random number between 0 and 16
          if (d > 0) {
            //Use timestamp until depleted
            r = (d + r) % 16 | 0;
            d = Math.floor(d / 16);
          } else {
            //Use microseconds since page-load if supported
            r = (d2 + r) % 16 | 0;
            d2 = Math.floor(d2 / 16);
          }
          return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
        }
      );
    },
    pollVoucher(voucherTP) {
      setTimeout(() => {
        if (this.pollCount < this.pollMax) {
          this.pollCount++;
          // delete the added property as it is not known to the backend
          this.$delete(voucherTP, "displaySummary");
          this.$delete(voucherTP, "expireIn");
          
          this.redeemed = false;

          // console.log("voucherToPoll: " + voucherTP.voucherId);
          Comms.sendToServer(
            "POST",
            "getScannedVoucher",
            voucherTP,
            this.errors
          ).then((response) => {
            // console.log("voucher response.status" + response.status);
            if (response.status === 200) {
              this.voucher = JSON.parse(response.data);
              if (this.voucher.status === "REDEEMED") {
                // display success screen
                if (this.$route.name === "QR") {
                  this.redeemed = true;
                  this.showQR = false;
                }
              } else {
                if (this.$route.name === "QR") {
                  // still on the qr screen
                  this.pollVoucher(voucherTP);
                } else {
                  // console.log("not polling anymore");
                }
              }
            }
          });
        } else {
          // go back to home screen
          this.$router.push({ name: "Home" });
        }
      }, this.pollInterval);
    },
    pollQR(trackTP) {
      this.redeemed = false;
      setTimeout(() => {
        if (this.pollCount < this.pollMax) {
          this.pollCount++;
          Comms.sendToServer(
            "POST",
            "getLastQRTrack",
            trackTP,
            this.errors
          ).then((response) => {
            // console.log("qr response" + response.status);
            if (response.status === 200) {
              this.trackReward = JSON.parse(response.data);
              // console.log("Status of reward: " + this.trackReward.status);
              if (this.trackReward.status === "ACTIVE") {
                // display success screen
                if (this.$route.name === "QR") {
                  this.allocationDone = true;
                  this.showQR = false;
                }
              } else if (this.trackReward.status === "ENDOFTLIFE") {
                // voucher generated
                if (this.$route.name === "QR") {
                  this.goalReached = true;
                  this.showQR = false;

                }
              } else {
                if (this.$route.name === "QR") {
                  // still on the qr screen
                  this.pollQR(trackTP);
                } else {
                  // console.log("not polling anymore");
                }
              }
            } else if (response.status === 204) {
              if (this.$route.name === "QR") {
                // still on the qr screen
                this.pollQR(trackTP);
              } else {
                // console.log("not polling anymore");
              }
            }
          });
        } else {
          // go back to home screen
          this.$router.push({ name: "Home" });
        }
      }, this.pollInterval);
    },
  },
  mounted: function () {
    this.initQrScreen();
  },
};
</script>

<style>
.centerQR {
  justify-content: center;
  align-items: center;
  display: flex;
  padding: 30px 0 30px 0;
}
</style>